<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 모두 장바구니 담기 버튼 -->
    <section id="modalResultCart" class="layer_dialog">
      <h2 class="mb20">모두 장바구니 담기 버튼</h2>
      <table class="tbl_noline type2">
        <caption>
          모두 장바구니 담기 버튼
        </caption>
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 75%" />
        </colgroup>
        <tbody>
          <tr v-show="is_desktop">
            <th scope="row">버튼 크기</th>
            <td class="input_td">
              <input
                type="number"
                name=""
                v-model="button.size"
                min="0"
                @blur="onlyNumber($event)"
              />
              px
              <font class="txt_comt mt20 ml12">※ 150~800 px 입력가능</font>
            </td>
          </tr>
          <tr>
            <th scope="row">버튼 색상</th>
            <td class="input_td">
              <div class="color">
                <label for="btnBgAllCartColor">
                  <input
                    type="text"
                    class="minicolors"
                    v-model="button.color"
                    id="btnBgAllCartColor"
                    ref="minicolors[0]"
                    maxlength="7"
                    @input="onlyHtmlColor($event, 'color')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(button.color)"
                ></span>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">버튼 테두리 색상</th>
            <td class="input_td">
              <div class="color">
                <label for="btnBgAllCartBorderColor">
                  <input
                    type="text"
                    class="minicolors"
                    v-model="button.border_color"
                    id="btnBgAllCartBorderColor"
                    ref="minicolors[0]"
                    maxlength="7"
                    @input="onlyHtmlColor($event, 'border_color')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(button.border_color)"
                ></span>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">버튼명 색상</th>
            <td class="input_td">
              <div class="color">
                <label for="btnTextAllCartColor"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="button.text_color"
                    id="btnTextAllCartColor"
                    ref="minicolors[1]"
                    maxlength="7"
                    @input="onlyHtmlColor($event, 'text_color')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(button.text_color)"
                ></span>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">버튼명</th>
            <td class="input_td">
              <input
                id="button_text"
                type="text"
                name=""
                v-model.trim="button.text"
                :maxlength="20"
                style="width: 100%"
                @keyup="inputText($event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 모두 장바구니 담기 버튼 -->
  </vue-final-modal>
</template>

<script>
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    button: {
      size: '',
      color: '',
      text: '',
      text_color: '',
      border_color: '',
    },
    is_desktop: false,
  }),
  props: ['item', 'type', 'result_desktop_items', 'result_mobile_items'],
  created: function () {
    this.init();
  },
  computed: {},
  mounted() {
    this.$nextTick(function () {
      const self = this;

      $('#modalResultCart .minicolors').minicolors({
        animationEasing: 'swing',
        keywords: '#',

        change(hex, opacity) {
          const id = $(this).prop('id');

          if (id === 'btnBgAllCartColor') self.button.color = hex;
          if (id === 'btnTextAllCartColor') self.button.text_color = hex;
          if (id === 'btnBgAllCartBorderColor') self.button.border_color = hex;
        },
      });

      $('#btnBgAllCartColor').minicolors('value', this.button.color);
      $('#btnTextAllCartColor').minicolors('value', this.button.text_color);
      $('#btnBgAllCartBorderColor').minicolors(
        'value',
        this.button.border_color,
      );
    });
  },
  methods: {
    init: function () {
      if (this.item !== null) {
        this.button.size = this.item.cart_button.size;
        this.button.color = this.item.cart_button.color;
        this.button.text = this.item.cart_button.text;
        this.button.text_color = this.item.cart_button.text_color;
        this.button.border_color = this.item.cart_button.border_color;
      } else {
        this.button.size = 500;
        this.button.color = '#3498db';
        this.button.border_color = '#3498db';
        this.button.text = '모두 장바구니 담기';
        this.button.text_color = '#ffffff';
      }
      if (this.type == 'desktop') {
        this.is_desktop = true;
      }
    },
    setResult: function () {
      this.button.text = this.replaceHtml(this.button.text);

      if (this.button.size === '') {
        if (this.is_desktop == true) {
          alert('버튼 크기를 입력해주세요.');
          return false;
        } else {
          this.button.size = 0;
        }
      } else if (this.button.color === '') {
        alert('버튼 색상을 입력해주세요.');
        return false;
      } else if (this.button.text_color === '') {
        alert('버튼명 색상을 입력해주세요.');
        return false;
      } else if (this.button.text === '') {
        alert('버튼명을 입력해주세요.');
        return false;
      } else if (!this.button.border_color || this.button.border_color === '') {
        alert('버튼 테두리 색상을 입력해주세요.');
        return false;
      }

      const params = {
        type: 'cart_button',
        size: this.button.size,
        color: this.button.color,
        text: this.button.text,
        text_color: this.button.text_color,
        border_color: this.button.border_color,
      };

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.result_desktop_items.splice(this.item.index, 1, params);
        } else {
          this.result_mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.result_desktop_items.push(params);
        } else {
          this.result_mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.result_desktop_items);
      this.$emit('updateList', 'mobile', this.result_mobile_items);

      this.show_modal = false;
    },
    onlyNumber: function (event) {
      let value = event.target.value.replace(/[^0-9.]/g, '');
      if (value === '' || value <= 0) {
        value = 0;
      }

      value = parseInt(value);

      if (this.type != 'desktop') {
        this.button.size = value;
        return;
      }

      if (value <= 150) {
        value = 150;
      } else if (value >= 800) {
        value = 800;
      }

      this.button.size = value;
      event.target.value = value;
    },
    onlyHtmlColor: function (event, key) {
      const value = event.target.value.replace(/[^#0-9a-zA-Z]/g, '');

      if (key === 'color') this.button.color = value;
      else if (key === 'text_color') this.button.text_color = value;
      else if (key === 'border_color') this.button.border_color = value;

      event.target.value = value;
    },
    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'button_text') {
        this.button.text = value;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ml12 {
  margin-left: 12px;
}
</style>
